<!--
 * @Author: wxy
 * @Date: 2022-07-05 14:11:41
 * @LastEditTime: 2022-10-26 18:43:47
 * @Description: 招聘信息添加/编辑
-->
<template>
  <div>
    <div class="header">
      <ds-header :title="headerTitle" style="width: 100%"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" :rules="rules">
      <a-form-model-item label="企业名称" prop="enterpriseId">
        <a-select
          :getPopupContainer="
            (triggerNode) => {
              return triggerNode.parentNode;
            }
          "
          :disabled="!!$route.query.id"
          style="width: 800px"
          show-search
          placeholder="请输入企业名称以查询"
          :filter-option="false"
          :not-found-content="null"
          @select="selectEnterPrise"
          @search="handleSearch"
          v-model="selectData"
          @mouseenter="handleClear"
          :allowClear="true"
        >
          <a-select-option v-for="item in allEnterprise" :key="item.enterpriseId" :value="item.enterpriseId">
            {{ item.enterpriseName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="岗位名称" prop="jobName">
        <a-input
          style="width: 800px"
          placeholder="请输入岗位名称，不超过20个字符"
          :max-length="20"
          v-model.trim="form.jobName"
        />
      </a-form-model-item>
      <a-form-model-item label="岗位类型" prop="jobTypeIds">
        <a-select
          mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 800px"
          placeholder="请选择岗位类型"
          v-model="form.jobTypeIds"
        >
          <a-select-option v-for="item in jobTypeList" :key="item.id" :value="item.id">
            {{ item.commonsValueName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="学历要求" prop="degreeRequire">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 800px"
          placeholder="请选择学历要求"
          v-model="form.degreeRequire"
        >
          <a-select-option v-for="item in degreeTypeList" :key="item.id" :value="item.id">
            {{ item.commonsValueName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="工作年限要求" prop="workingYearsRequire">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 800px"
          placeholder="请选择工作年限要求"
          v-model="form.workingYearsRequire"
        >
          <a-select-option v-for="item in workingYearsList" :key="item.id" :value="item.id">
            {{ item.commonsValueName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="职责描述" prop="jobDescription">
        <a-textarea
          style="width: 800px; position: relative"
          v-model="form.jobDescription"
          placeholder="请输入职责描述，不超过500个字符"
          :maxlength="500"
          :autosize="{ minRows: 5, maxRows: 5 }"
        />
        <div class="tips">{{ form.jobDescription.length }}/500</div>
      </a-form-model-item>
      <a-form-model-item label="岗位要求" prop="jobRequire">
        <a-textarea
          style="width: 800px; position: relative"
          v-model="form.jobRequire"
          placeholder="请输入岗位要求，不超过500个字符"
          :maxlength="500"
          :autosize="{ minRows: 5, maxRows: 5 }"
        />
        <div class="tips">{{ form.jobRequire.length }}/500</div>
      </a-form-model-item>
      <a-form-model-item label="就职园区" prop="projectId">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 800px"
          placeholder="请选择就职园区"
          v-model="form.projectId"
        >
          <a-select-option v-for="item in projectList" :key="item.projectId" :value="item.projectId">
            {{ item.projectName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="薪资类型" :span="24" prop="salaryType">
        <a-radio-group v-model="form.salaryType" @change="cleanMoney">
          <!-- <a-radio :value="0"> 固定区间 </a-radio>
          <a-radio :value="1"> 面议 </a-radio> -->
          <a-radio :value="0"> 月薪 </a-radio>
          <a-radio :value="2"> 日薪 </a-radio>
          <a-radio :value="1"> 面议 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.salaryType !== 1" label="薪资区间" :span="24" prop="salaryRange">
        <a-radio-group v-model="form.salaryRange"  @change="cleanMoney">
          <a-radio :value="0"> 可选区间 </a-radio>
          <a-radio :value="1"> 固定区间 </a-radio>
        </a-radio-group>
        <!-- 可选薪资范围区间 -->
        <a-row v-if="(form.salaryType === 0 ||form.salaryType === 2) && form.salaryRange === 0" :span="24">
          <a-col :span="3">
            <a-form-model-item
              key="salaryRangeMin"
              prop="salaryRangeMin"
              :labelCol="{
                xs: { span: 24 },
                sm: { span: 24 },
              }"
              :wrapperCol="{
                xs: { span: 24 },
                sm: { span: 24 },
              }"
            >
              <a-input v-model.trim="form.salaryRangeMin" :placeholder="form.salaryType===0?'最低月薪':'最低日薪'" style="margin-left: 5px" />
            </a-form-model-item>
          </a-col>
          <a-col :span="1" style="text-align: center;">-</a-col>
          <a-col :span="3">
            <a-form-model-item
              key="salaryRangeMax"
              prop="salaryRangeMax"
              :labelCol="{
                xs: { span: 24 },
                sm: { span: 24 },
              }"
              :wrapperCol="{
                xs: { span: 24 },
                sm: { span: 24 },
              }"
            >
              <a-input v-model.trim="form.salaryRangeMax" :placeholder="form.salaryType===0?'最高月薪':'最高日薪'" />
            </a-form-model-item>
          </a-col>
          <a-col :span="1" v-if="form.salaryType === 0" style="text-align: center;">.</a-col>
          <a-col :span="3" v-if="form.salaryType === 0">
          <a-form-model-item  prop="annualSalary">
              <a-select
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                placeholder="请选择薪资月数"
                v-model="form.annualSalary"
              >
                <a-select-option v-for="item in annualSalaryList" :key="item" :value="item">
                  {{ item }} 
                </a-select-option>
              </a-select>
          </a-form-model-item>
          </a-col>
        <a-col :span="5" style="text-align: center; margin-left: -30px">单位：{{ form.salaryType===0?'千元/月':'元/日' }}</a-col>
      </a-row>
      <!-- 固定薪资 -->
      <a-row v-if="(form.salaryType === 0 ||form.salaryType === 2) && form.salaryRange === 1" :span="24">
        <a-col :span="3">
          <a-form-model-item
            key="regular"
            prop="salaryRangeMin"
            :labelCol="{
             xs: { span: 24 },
                sm: { span: 24 },
            }"
            :wrapperCol="{
              xs: { span: 24 },
                sm: { span: 24 },
            }"
          >
            <a-input v-model.trim="form.salaryRangeMin" :placeholder="form.salaryType===0?'固定月薪':'固定日薪'" style="margin-left: 5px" />
          </a-form-model-item>
        </a-col>
        <a-col :span="1"  v-if="form.salaryType === 0" style="text-align: center;">.</a-col>
        <a-col :span="3" v-if="form.salaryType === 0">
          <a-form-model-item  prop="annualSalary">
              <a-select
                key="annualSalary"
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                placeholder="请选择薪资月数"
                v-model="form.annualSalary"
              >
                <a-select-option v-for="item in annualSalaryList" :key="item" :value="item">
                  {{ item }}
                </a-select-option>
              </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="5" style="text-align: center; margin-left: -30px">单位：{{ form.salaryType===0?'千元/月':'元/日' }}</a-col>
      </a-row>
      </a-form-model-item>
    
      <a-form-model-item label="简历投递邮箱" prop="email">
        <a-input style="width: 800px" placeholder="请输入简历投递邮箱" v-model.trim="form.email" />
      </a-form-model-item>
      <a-form-model-item label="是否发布" :span="24" prop="publishStatus">
        <a-radio-group v-model="form.publishStatus">
          <a-radio :value="0"> 是 </a-radio>
          <a-radio :value="1"> 否 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 6, offset: 8 }">
        <a-button type="primary" @click="submit" style="margin-right: 20px"> 确定 </a-button>
        <a-button type="info" @click="goBack"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import * as api from "../../../../api/socialRecruitingServicesManagement";
import debounce from "lodash/debounce";
export default {
  name: "add",
  data() {
    let checkJobTypeIds = (rule, value, callback) => {
      if (this.form.jobTypeIds.length > 3) {
        return callback(new Error("岗位类型的选择不超过三个"));
      }
      return callback();
    };
    let checkSalaryRangeMin = (rule, value, callback) => {
      let regex = /^([1-9]\d{0,2}|[0]{1,1})$/;
      if (!regex.test(value)) {
        return callback(new Error("最多不超过三位数"));
      } else if (this.form.salaryRangeMax  && Number(value) > Number(this.form.salaryRangeMax)) {
        return callback(new Error("正确填写最低薪资"));
      }
      return callback();
    };
    let requiredSalaryRangeMin = (rule, value, callback) => {
      if(value)  return callback();
      if(this.form.salaryRange === 0){
        if(this.form.salaryType ===0){
              return callback(new Error("请输入最低月薪"));
            }else if(this.form.salaryType ===2){
              return callback(new Error("请输入最低日薪"));
            }
      }else if(this.form.salaryRange === 1){
        if(this.form.salaryType ===0){
              return callback(new Error("请输入固定月薪"));
            }else if(this.form.salaryType ===2){
              return callback(new Error("请输入固定日薪"));
            }
      }
    };
    let checkSalaryRangeMax = (rule, value, callback) => {
      let regex = /^([1-9]\d{0,2}|[0]{1,1})$/;
      if (!regex.test(value)) {
        return callback(new Error("最多不超过三位数"));
      } else if (this.form.salaryRangeMin !== "" && Number(value) < Number(this.form.salaryRangeMin)) {
        return callback(new Error("正确填写最高薪资"));
      }
      return callback();
    };
    let requiredSalaryRangeMax = (rule, value, callback) => {
      if(value)  return callback();
      if(this.form.salaryRange === 0){
        if(this.form.salaryType ===0){
              return callback(new Error("请输入最高月薪"));
            }else if(this.form.salaryType ===2){
              return callback(new Error("请输入最高日薪"));
            }
      }
    };
    return {
      // 标题
      headerTitle: "",
      form: {
        // 企业名称
        enterpriseId: undefined,
        // 岗位类型
        jobTypeIds: [],
        // 学历要求
        degreeRequire: undefined,
        // 工作年限要求
        workingYearsRequire: undefined,
        // 职责描述
        jobDescription: "",
        // 岗位要求
        jobRequire: "",
        // 就职园区
        projectId: undefined,
        // 薪资类型
        salaryType: 0,
        // 薪资区间最小值
        salaryRangeMin: "",
        // 薪资区间最大值
        salaryRangeMax: "",
        // 简历投递邮箱
        email: "",
        // 是否发布
        publishStatus: 0,
        // 区间类型
        salaryRange: 1,
        // 十几薪
        annualSalary:undefined,
      },
      rules: {
        enterpriseId: [{ required: true, message: "请选择企业名称", trigger: "blur" }],
        jobName: [{ required: true, message: "请输入岗位名称", trigger: "blur" }],
        jobTypeIds: [
          { required: true, message: "请选择岗位类型", trigger: "change" },
          { validator: checkJobTypeIds, required: true, trigger: "change" },
        ],
        degreeRequire: [{ required: true, message: "请选择学历要求", trigger: "change" }],
        workingYearsRequire: [{ required: true, message: "请选择工作年限要求", trigger: "change" }],
        jobDescription: [{ required: true, message: "请输入职责描述", trigger: "blur" }],
        jobRequire: [{ required: true, message: "请输入岗位要求", trigger: "blur" }],
        projectId: [{ required: true, message: "请选择就职园区", trigger: "change" }],
        salaryType: [{ required: true, message: "请选择薪资类型", trigger: "change" }],
        salaryRangeMin: [
          { required: true, validator: requiredSalaryRangeMin, trigger: "blur" },
          { validator: checkSalaryRangeMin, required: true, trigger: "blur" },
        ],
        salaryRangeMax: [
          { required: true, validator: requiredSalaryRangeMax, trigger: "blur" },
          { validator: checkSalaryRangeMax, required: true, trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入简历投递邮箱", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9]+([._\\-]*[a-zA-Z0-9])*@([a-zA-Z0-9]+[-a-zA-Z0-9]*[a-zA-Z0-9]+.){1,63}[a-zA-Z0-9]+$/,
            message: "请输入正确格式的邮箱",
            trigger: "blur",
          },
        ],
        publishStatus: [{ required: true, message: "请选择是否发布", trigger: "change" }],
        salaryRange:[{ required: true, message: "请选择薪资类型", trigger: "change" }],
        annualSalary: [{ required: true, message: "请选择薪资月数", trigger: "change" }],
      },
      // 项目
      projectList: [],
      // 岗位类型
      jobTypeList: [],
      // 学历类型
      degreeTypeList: [],
      // 工作年限
      workingYearsList: [],
      // 是否禁用
      disabled: false,
      // 企业名称
      allEnterprise: [],
      // 十几薪列表
      annualSalaryList:[
        '暂不选择','13','14','15','16','17','18','19','20'
      ]
    };
  },
  watch:{
    // 去除切换薪资类型的校验提示
    'form.salaryType' (newValue) {
        this.$refs.ruleForm.clearValidate(['salaryRangeMin','salaryRangeMax']);
    },
  },
  computed: {
    // 默认选中
    selectData: {
      get: function () {
        return !!this.$route.query.id ? this.form.enterpriseName : this.form.enterpriseId;
      },
      set: function (val) {},
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.headerTitle = "编辑招聘信息";
      this.getDetail();
    } else {
      this.headerTitle = "新增招聘信息";
    }
    // 获取下拉数据
    this.getSelectList();
  },
  methods: {
    // 获取详情数据
    async getDetail() {
      try {
        const recruitmentId = this.$route.query.id;
        const res = await api.recruitmentDetail(recruitmentId);
        if (res.code === "200") {
          res.data.jobTypeIds = [];
          this.form = res.data;
          if( res.data.annualSalary === null){
            this.form.annualSalary ='暂不选择'
          }
          res.data.jobTypeList.forEach((item) => {
            this.form.jobTypeIds.push(item.jobTypeId);
          });
          // 企业搜索优化
          const data = {
            enterpriseName: this.form.enterpriseName,
            page: 0,
            size: 100,
          };
          const result = await api.SPECIAL_POSITION_GETENTERPRISE(data);
          this.projectList =  result.data.enterpriseList[0].projectList;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 确定
    submit() {
      let obj = {
        ...this.form,
      };
      console.log(obj,'=====>');
      obj.jobTypeIds = obj.jobTypeIds.join(",");
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.$route.query.id) {
            const res = await api.editRecruitment(obj);
            if (res.code === "200") {
              this.$message.success("编辑成功");
              this.$router.push({
                path: "/socialRecruitingServicesManagement/recruitmentInformationManagement",
              });
            } else {
              this.$message.error(res.msg);
            }
          } else {
            try {
              const res = await api.addRecruitment(obj);
              if (res.code === "200") {
                this.$message.success("添加成功");
                this.$router.push({
                  path: "/socialRecruitingServicesManagement/recruitmentInformationManagement",
                });
              } else {
                this.$message.error(res.msg);
              }
            } catch (error) {}
          }
        } else {
        }
      });
    },
    // 取消
    goBack() {
      this.$router.go(-1);
    },
    // 获取下拉数据
    async getSelectList() {
      try {
        const res = await api.recruitmentSelectList();
        if (res.code === "200") {
          this.jobTypeList = res.data.jobTypeList;
          this.degreeTypeList = res.data.degreeTypeList;
          this.workingYearsList = res.data.workingYearsList;
        }
      } catch (error) {}
    },

    //选择企业
    selectEnterPrise(value) {
      this.form.projectId = "";
      this.form.enterpriseId = value;
      this.getProject(value);
    },
    // 获取所属项目
    getProject(id) {
      this.allEnterprise.forEach((item) => {
        if (item.enterpriseId === id) {
          this.projectList = item.projectList;
        }
      });
    },
    handleSearch(value) {
      if (value.trim().length > 0) {
        // 输入时 根据value 远程拉去 数据
        this.getEnterprise(value.trim());
      }
    },
    getEnterprise: debounce(async function (keyWord) {
      try {
        // 企业搜索优化
        const data = {
          enterpriseName: keyWord,
          page: 0,
          size: 100,
        };
        const result = await api.SPECIAL_POSITION_GETENTERPRISE(data);
        this.allEnterprise = result.data.enterpriseList;
      } catch (err) {
        console.error(err);
      }
    }, 500),
    // 鼠标移入获取清空按钮
    handleClear(e) {
      // 获取到dom元素
      let clearDom = e.path[0].children[0].children[1];
      // 添加点击事件
      clearDom.addEventListener("click", () => {
        // 清空操作
        this.form.enterpriseId = undefined;
        this.projectList.length = 0;
      });
    },
    // 更换薪资类型
    cleanMoney(){
      this.form.salaryRangeMin = ""
      this.form.salaryRangeMax = ""
      this.form.annualSalary = undefined
    }
  },
};
</script>

<style lang='scss' scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.tips {
  color: #d9d9d9;
  position: absolute;
  bottom: -40px;
  right: 0;
}

</style>